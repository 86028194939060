import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
//export default () => (
class AboutPage extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={this.props.data.site.siteMetadata.title}
      >
        <h1>About me</h1>
        <p>Software developer / business owner</p>
        <p>You can find out more on links below</p>
        <Helmet
          script={[
            {
              src: '//platform.linkedin.com/in.js',
              type: 'text/javascript',
            },
          ]}
        />
        <Helmet>
          <script
            type="IN/MemberProfile"
            data-id="http://www.linkedin.com/in/arturassotnicenko"
            data-format="inline"
            data-related="false"
          ></script>
        </Helmet>
        <Helmet>
          <script src="https://unpkg.com/github-card@1.2.1/dist/widget.js"></script>
        </Helmet>
        <div class="github-widget" data-username="chillerfx"></div>
      </Layout>
    )
  }
}
export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
